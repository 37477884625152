import React from 'react'
import cx from 'classnames'

import BlockContent from '@sanity/block-content-to-react'

import { Serializer } from 'src/utils/serializer'
import { Image } from 'src/components/image'

export interface TextBlockProps {
  data: {
    text: any[]
  }
}

export const TextBlock = ({ data }: TextBlockProps) => {
  const {
    text,
    layout
  } = data
  return (
    <div className='x pr text__block df jcc aic'>
      <div className='tc p2 p5 p8--800 pr z2 '>
        <div className={cx('mxa p2', layout)}>
          <BlockContent blocks={text} serializers={Serializer} />
        </div>
      </div>
    </div>
  )
}

export default TextBlock